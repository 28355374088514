import React from 'react'
import Layout from "../components/App/Layout"
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
// import ContactForm from '../components/Contact/ContactForm'
import Navbarnew from '../new-components/nav-bar'
import BannerContact from '../new-components/contactBanner'
const Contact = () => {
    return (
        <Layout>
            <Navbarnew/>
            <BannerContact/>
            <ContactInfo />
            {/* <ContactForm /> */}
            <Footer />
        </Layout>
    );
}

export default Contact