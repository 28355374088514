import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Direccion</h3>
                            <p>Av. Álvaro Obregón No . 250 Piso 2 ,Alcadía Cuautémoc, CP 067000, Mexico</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contacto</h3>
                            <p>Oficina: <a href="tel:+525522827221">+52(55 )2282-7221</a></p>
                            <p>Movil: <a href="tel:+528672047789">+52(867)204-7789</a></p>
                            <p>E-mail: <a href="mailto:jstavin@aajking.com">jstavin@aajking.com</a></p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bxl-whatsapp'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bxl-whatsapp'></i>
                            </div>
                            <h3>WhatsApp</h3>
                            <p>WhatsApp: <a className="whatcolor" href="https://wa.me/+528672047789" target="_blank" rel="noreferrer">Contactar Ahora</a></p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>Horarios de Operciones</h3>
                            <p>Lunes -  Viernes: 09:00 - 20:00</p>
                            <p>Sabado & Domingo: 10:30 - 22:00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo