import React from 'react'
import bancontact from '../assets/images/img_final/img_compress/ban_contact.webp'

const BannerContact = () => {
    const backcontact = {
        backgroundImage:`url(${bancontact})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
    }
    return (
        <>
            <section className="py-5" style={backcontact}>
                <div className="container py-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <div className="col-xl-12 py-5">
                            <h1 className="text-dark pt-5">¿Tienes dudas?</h1>
                            <h5 className="text-dark">Contactanos Ahora</h5>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}




export default BannerContact